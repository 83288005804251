export default {
  created() {
    this.boundPreventContextMenu = this.preventContextMenu
  },
  methods: {
    initContextMenuListener() {
      if (!this.$store.state.contextMenu.isListenerActive) {
        document.addEventListener('contextmenu', this.boundPreventContextMenu)
        this.$store.commit('contextMenu/enableEventListener')
      }
    },
    clearContextMenuListener() {
      document.removeEventListener('contextmenu', this.boundPreventContextMenu)
      this.$store.commit('contextMenu/disableEventListener')
    },
    preventContextMenu(event) {
      if (this.$store.state.contextMenu.disableContextMenu) {
        event.preventDefault()
      }
    },
  },
}
