import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5b0c7c0c&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=5b0c7c0c&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DSButton: require('/usr/src/app/components/DS/Button/Button.vue').default,Loader: require('/usr/src/app/components/Loader.vue').default})
