
import userAvatar from '~/assets/images/user.png'

export default {
  name: 'SocialNewPost',
  props: {
    isHomePage: {
      type: Boolean,
      default: false,
    },
    isProfilePage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userAvatar() {
      const fallbackImage = userAvatar
      return this.$auth.user?.avatar || fallbackImage
    },
    userNickname() {
      return this.$auth.user?.nickname || 'avatar'
    },
  },
  methods: {
    handleClick(value) {
      if (this.isHomePage) {
        this.$gtm.push({
          event: 'post_creation_homepage_attempt',
        })
      } else {
        const query = this.$route.query.tab

        if (query === 'feed') {
          this.$gtm.push({
            event: 'post_creation_feed_attempt',
          })
        } else {
          this.$gtm.push({
            event: 'post_creation_profile_attempt',
          })
        }
      }

      this.$router.push({ path: '/post', query: { type: value } })
    },
    isShowDark() {
      if (this.$route.name !== 'nickname') return true
      if (this.$route.name === 'nickname' && this.$colorMode.preference === 'dark') return true
      return false
    },
  },
}
