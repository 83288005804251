
export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
    page: {
      type: String,
      default: 'post',
    },
    activeCategory: {
      type: String,
      default: undefined,
    },
    startCenter: {
      type: Boolean,
      default: true,
    },
    isOnTimelineComponent: {
      type: Boolean,
      default: false,
    },
    isDefaultFilterActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLeftArrow: false,
      showRightArrow: true,
      selectedCategory: undefined,
      startX: 0,
      scrollLeftStart: 0,
      isFullWidth: false,
    }
  },
  watch: {
    activeCategory: {
      immediate: true,
      handler(newVal) {
        this.selectedCategory = newVal
      },
    },
    categories: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.updateWidthCheck()
          this.checkScroll()
        })
      },
    },
  },
  mounted() {
    this.checkScroll()
    if (this.startCenter) {
      this.updateWidthCheck()
      window.addEventListener('resize', this.updateWidthCheck)
    }
  },
  beforeDestroy() {
    if (this.startCenter) {
      window.removeEventListener('resize', this.updateWidthCheck)
    }
  },
  methods: {
    updateWidthCheck() {
      const { scroller } = this.$refs
      const totalWidth = Array.from(scroller.querySelectorAll('.item'))
        .reduce((total, item) => total + item.offsetWidth + 12, 0)
      this.isFullWidth = scroller.offsetWidth < totalWidth
    },
    scrollLeft() {
      this.$refs.scroller.scrollBy({ left: -this.getItemWidth(), behavior: 'smooth' })
    },
    scrollRight() {
      this.$refs.scroller.scrollBy({ left: this.getItemWidth(), behavior: 'smooth' })
    },
    checkScroll() {
      const { scroller } = this.$refs
      this.showLeftArrow = scroller.scrollLeft > 0
      this.showRightArrow = scroller.scrollLeft + scroller.clientWidth < scroller.scrollWidth
    },
    getItemWidth() {
      const firstItem = this.$refs.scroller.querySelector('.item')
      return firstItem ? firstItem.clientWidth + 16 : 0 // 16px for margin
    },
    selectCategory(category) {
      if (this.selectedCategory !== category) {
        this.selectedCategory = category
        this.$emit('category-selected', category)
      }
      this.sentTracker()
    },
    sentTracker() {
      this.$gtm.push({
        event: this.page === 'post' ? 'supporter_post_tag_filter' : 'supporter_shop_tag_filter',
        user_id: this.$auth?.user?.id || null,
      })
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].pageX
      this.scrollLeftStart = this.$refs.scroller.scrollLeft
    },
    handleTouchMove(event) {
      event.preventDefault()
      const touch = event.touches[0]
      const walk = (this.startX - touch.pageX) * 2 // multiply by 2 for faster swiping
      this.$refs.scroller.scrollLeft = this.scrollLeftStart + walk
    },
    handleTouchEnd() {
      this.checkScroll()
    },
  },
}
