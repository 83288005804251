
import dayjs from 'dayjs'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import {
  mapActions, mapState, mapMutations, mapGetters,
} from 'vuex'
import UserSocial from '~/containers/UserProfile/Social/index.vue'
import { showError } from '~/utils'
import contextMenu from '~/mixins/context-menu'

// Group profile-related components
const ProfileHeader = () => import(/* webpackChunkName: "profile-header" */ '~/containers/NewUserProfile/ProfileHeader.vue')
const UserAbout = () => import(/* webpackChunkName: "profile-about" */ '~/containers/UserProfile/About/index.vue')
const UserMembership = () => import(/* webpackChunkName: "profile-membership" */ '~/containers/UserProfile/Membership/index.vue')
const UserServices = () => import(/* webpackChunkName: "profile-services" */ '~/containers/UserProfile/UserServices.vue')
const UserCommissions = () => import(/* webpackChunkName: "profile-commissions" */ '~/containers/UserProfile/UserCommissions.vue')
const OnboardingTaskList = () => import(/* webpackChunkName: "onboarding" */ '~/containers/UserProfile/About/OnboardingTaskList.vue')

const DSButton = () => import('~/components/DS/Button/Button.vue')
const SuccessDonateModal = () => import('~/components/Modal/DonationModal/SuccessDonateModal.vue')
const NSFWAlert = () => import('~/components/NSFW/NSFWAlert.vue')
const NSFWWarningModal = () => import('~/components/NSFW/NSFWWarningModal.vue')
const AlertVerifyEmail = () => import('~/containers/UserProfile/About/AlertVerifyEmail.vue')
const SupporterViewNotice = () => import('~/containers/UserProfile/SupporterViewNotice.vue')

export default {
  components: {
    UserSocial,
    UserServices,
    UserCommissions,
    UserAbout,
    ProfileHeader,
    DSButton,
    UserMembership,
    SuccessDonateModal,
    NSFWAlert,
    NSFWWarningModal,
    // Alert,
    AlertVerifyEmail,
    OnboardingTaskList,
    SupporterViewNotice,
  },
  filters: {
    hisOrHer(val) {
      if (!val) return 'his'
      if (val.toLowerCase() === 'male') return 'his'
      if (val.toLowerCase() === 'female') return 'her'
      return 'his'
    },
  },
  mixins: [contextMenu],
  async asyncData({
    query, redirect, params,
  }) {
    try {
      if (query.type === 'download') {
        return redirect('/download')
      }

      if (params.nickname !== params.nickname.toLowerCase()) {
        return redirect({
          name: 'nickname',
          params: { nickname: params.nickname.toLowerCase() },
          query,
        })
      }
    } catch (e) {
      // show error
    }
    return {}
  },
  data() {
    return {
      membership: [],
      activeKey: 'about',
      canonical: '',
      stickyTabbar: null,
      stickySidebar: null,
      successModal: false,
      successDonor: {
        message: '',
        name: '',
        quantity: 0,
      },
      successGoal: null,
      sidebarObserver: null,
      userMedia: [],
      userBackground: [],
      userVideo: [],
      isMounted: false,
      userMeta: null,
      userNSFWTag: [],
      userSuspended: false,
      nsfwWarningModalVisible: false,
      showNSFWalert: false,
      lastDonorAndTotalSupporter: {},
      stickyProfileSidebar: null,
      resizeObserver: null,
      userPinnedPosts: undefined,
    }
  },
  head() {
    let ogImage = []
    if (this.userBackground && this.userBackground.length > 0) {
      const ogImageCropped = `${this.userBackground[0].url}=w1200-h300-c`
      ogImage = [{ property: 'og:image', content: ogImageCropped.replace('http://', 'https://') }]
    } else if (this.userMeta && this.userMeta.avatar) {
      ogImage = [{ property: 'og:image', content: `${this.userMeta?.avatar}=s512` }]
    } else {
      ogImage = this.userBackground?.map(v => ({
        property: 'og:image',
        content: v.url || '',
      }))
    }
    const ogData = [
      { property: 'og:title', content: `${this.$route.params.nickname}'s profile - Gank` },
      { property: 'og:description', content: this.pageDescription },
      { property: 'og:url', content: `${this.$config.gankUrl + this.$route.path}` },
      { property: 'og:height', content: 300 },
      { property: 'og:width', content: 1200 },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: this.pageTitle },
      { name: 'twitter:description', content: this.pageDescription },
      { name: 'twitter:image', content: ogImage && ogImage.length > 0 ? ogImage[0].content : '' },
    ]
    if (this.isNsfw) {
      ogData.push({ name: 'robots', content: 'noindex, nofollow' })
    }

    return {
      title: this.pageTitle,
      __dangerouslyDisableSanitizers: ['title'],
      meta: [
        ...ogData,
        ...ogImage,
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://ganknow.com/${this.$route.params.nickname.toLowerCase()}`,
        },
      ],
      script: [
        {
          src: 'https://cdn.jsdelivr.net/npm/resize-sensor@0.0.6/ResizeSensor.min.js',
          type: 'text/javascript',
          async: true,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      goal: state => state.profile.userGoal,
      userServices: state => state.catalogs.userServices,
      userCommissions: state => state.catalogs.userCommissions,
      userDiscountPPV: state => state.profile.userDiscountPPV,
      userDiscountShop: state => state.profile.userDiscountShop,
      userDiscountMembership: state => state.profile.userDiscountMembership,
    }),
    ...mapGetters({
      isContextSupporter: 'menu/isContextSupporter',
    }),
    pageTitle() {
      return `${this.$route.params.nickname}'s profile - Gank`
    },
    pageDescription() {
      const nickname = this.$route?.params?.nickname ? this.$route?.params?.nickname : ''
      const getDesc = `Unlock exclusive content and updates from ${nickname} instantly.  Get access to private posts, images, videos and more. Follow ${nickname} today, only on Gank.`
      return getDesc.substring(0, 300)
    },
    userTimezone() {
      let offset = dayjs().format('Z').split(':')[0]
      offset = offset.charAt(1) === '0' ? offset.replace('0', '') : offset
      return offset
    },
    isMyOwnProfile() {
      const nickname = get(this.$auth, 'user.nickname', '')
      return nickname.toLowerCase() === this.$route.params.nickname
    },
    isCreatorHasMembership() {
      return this.userMeta && this.userMeta.isMembershipEnabled
    },
    isMembershipEnabled() {
      const metaHasMembership = this.userMeta && this.userMeta.isMembershipEnabled && this.userMeta.has_membership

      if (this.isMyOwnProfile && this.isContextSupporter && metaHasMembership) {
        return true
      }

      if (this.isMyOwnProfile && !this.isContextSupporter) {
        return true
      }

      if (!this.isMyOwnProfile && metaHasMembership) {
        return true
      }

      return false
      // return this.isMyOwnProfile ? true : this.userMeta && this.userMeta.isMembershipEnabled && this.userMeta.has_membership
    },
    isShopExist() {
      const metaHasService = this.userMeta && this.userMeta.has_service

      if (this.isMyOwnProfile && this.isContextSupporter && metaHasService) {
        return true
      }

      if (this.isMyOwnProfile && !this.isContextSupporter) {
        return true
      }

      if (!this.isMyOwnProfile && metaHasService) {
        return true
      }

      return false
    },
    isCommissionExist() {
      const metaHasCommission = this.userMeta && this.userMeta.has_commission

      if (this.isMyOwnProfile && this.isContextSupporter && metaHasCommission) {
        return true
      }

      if (this.isMyOwnProfile && !this.isContextSupporter) {
        return true
      }

      if (!this.isMyOwnProfile && metaHasCommission) {
        return true
      }

      return false
    },
    enabledDonation() {
      if (this.isMyOwnProfile) {
        return this.$auth.user.isDonationEnabled
      }
      return this.userMeta && this.userMeta.isDonationEnabled === true
    },
    showProfileInterestAlert() {
      return this.isMyOwnProfile && (this.userMeta?.userInterests?.length ?? 0) === 0 && this.userMeta?.userType === 'creator'
    },
    isVerified() {
      return this.$auth.loggedIn && this.$auth.user && this.$auth.user.verified === true
    },
    userProfile() {
      return {
        categories: [],
        lowestServicePrice: {},
        user: this.userMeta,
      }
    },
    isNsfw() {
      if (this.userMeta && this.userMeta?.tags) {
        const nsfwTags = ['PORN', 'NSFW']
        const userTags = this.userMeta?.tags
        return userTags.some(tag => nsfwTags.includes(tag))
      }

      return false
    },
    userID() {
      return this.userProfile?.user?.id
    },
    isEnableSupporterCount() {
      if (!this.userMeta?.enableSupporterCount) return false

      return this?.userMeta?.enableSupporterCount
    },
    favoriteMembership() {
      if (this.membership) {
        return this.membership.find(item => item.isFavorite)
      }

      return []
    },
  },
  watch: {
    '$route.query': function changeKey(val) {
      if (val?.tab !== undefined && val.tab !== this.activeKey) {
        this.activeKey = val.tab
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('catalogs/setUserServices', [])
    window.removeEventListener('resize', this.resizeEvent)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    this.clearContextMenuListener()
  },
  async mounted() {
    try {
      await this.$store.commit('profile/resetState')
      await this.fetchUserData()
    } catch (error) {
      this.$nuxt.error({
        statusCode: 404,
        message: 'User could not be found',
      })
      return
    }

    this.checkDonationSuccess()
    this.handleTabQuery()

    if (this.userMeta && this.userMeta.id) {
      this.isMounted = true
      await this.fetchUserMedia()
      this.processUserTags()
      await this.fetchAdditionalData()
    }
    this.$nextTick(() => {
      this.setupUI()
    })
  },
  methods: {
    ...mapMutations({
      setUserProfile: 'profile/setUserProfile',
    }),
    ...mapActions({
      getServices: 'catalogs/getServices',
      getLanguages: 'settings/getLanguages',
      getUserGoal: 'profile/getUserGoal',
      getIsUserFollowing: 'profile/getIsUserFollowing',
      getUserOnboardingTask: 'profile/getUserOnboardingTask',
      getPinnedPostsByUserId: 'post/getPinnedPostsByUserId',
      getUserDiscountPPV: 'profile/getUserDiscountPPV',
      getUserDiscountShop: 'profile/getUserDiscountShop',
      getUserDiscountMembership: 'profile/getUserDiscountMembership',
    }),

    async fetchUserData() {
      let offset = dayjs().format('Z').split(':')[0]
      offset = offset.charAt(1) === '0' ? offset.replace('0', '') : offset

      const { data: { data: userMeta } } = await this.$axios(`/users/nickname/${this.$route.params.nickname}?timezone=${offset}`)
      this.userMeta = userMeta

      this.setUserProfile(this.userMeta)
      this.trackProfileView()
    },
    trackProfileView() {
      if (!this.isMyOwnProfile) {
        this.$gtm.push({ event: 'profile_viewed' })
      }
    },
    handleTabQuery() {
      const tabQuery = this.$route.query.tab
      if (tabQuery) {
        this.activeKey = (tabQuery === 'membership' && this.isMembershipEnabled) || tabQuery !== 'membership' ? tabQuery : this.activeKey
      }
    },

    setupUI() {
      this.$nextTick(() => {
        if (this.$device.isDesktopOrTablet && this.$route.name === 'nickname') {
          this.$nextTick(() => {
            this.setStickyElement()
            this.setStickyProfileSidebar()
            this.observeProfileAboutHeight()
            this.initContextMenuListener()
            window.addEventListener('resize', this.resizeEvent)
          })
        }
      })
    },
    async fetchAdditionalData() {
      const promises = [
        this.getMembership(),
        this.getLanguages(),
        this.fetchUserFollowing(),
      ]

      if (this.isShopExist) {
        promises.push(this.getServices({ userId: this.userMeta.id }))
        if (!this.isMyOwnProfile) {
          this.getUserDiscountShop(this.userMeta.id)
        }
      }

      if (this.isCommissionExist) {
        promises.push(this.getServices({ userId: this.userMeta.id, catalog: 'service' }))
      }

      if (this.isCreatorHasMembership) {
        if (!this.isMyOwnProfile) {
          this.getUserDiscountMembership(this.userMeta.id)
        }
      }
      if (!this.isMyOwnProfile) {
        this.getUserDiscountPPV(this.userMeta.id)
      }

      const results = await Promise.allSettled(promises)

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          console.error(`Failed to fetch data for promise ${index}:`, result.reason)
        }
      })

      if (this.isMyOwnProfile && this.$auth.loggedIn) {
        try {
          await this.getUserOnboardingTask()
        } catch (error) {
          console.error('Failed to get user onboarding task:', error)
        }
      }

      if (!this.isMyOwnProfile && this.isEnableSupporterCount) {
        await this.fetchLastDonorAndTotalSupporter()
      }
    },
    async fetchUserMedia() {
      const { data: { data: mediaResponse } } = await this.$axios.get(`users/${this.userMeta.id}/profile-media`)
      this.userMedia = mediaResponse ?? []
      this.userBackground = this.userMedia.filter(item => item.format === 'image') ?? []
      this.userVideo = this.userMedia.filter(item => item.format === 'video') ?? []
    },
    processUserTags() {
      this.userNSFWTag = this.userMeta?.tags ?? []
      this.userSuspended = this.userMeta?.isSuspended ?? false
      this.handleNSFWWarning()
      this.handleSuspendedUser()
    },

    handleNSFWWarning() {
      const nsfwTags = ['PORN', 'NSFW']
      if (!this.isMyOwnProfile && this.userNSFWTag?.some(tag => nsfwTags.includes(tag))) {
        const nsfwCookie = this.$cookies.get(`nsfw_${this.userProfile?.user?.id}`)
        this.nsfwWarningModalVisible = !nsfwCookie
      }
    },

    handleSuspendedUser() {
      if (this.isMyOwnProfile && this.userProfile?.user?.isSuspended) {
        this.showNSFWalert = true
      }
    },

    async fetchUserFollowing() {
      if (!this.isMyOwnProfile && this.$auth.loggedIn) {
        await this.getIsUserFollowing(this.userProfile?.user?.id)
      }
    },

    checkDonationSuccess() {
      const donor = this.$cookies.get('success-donor')
      const goal = this.$cookies.get('success-goal')
      const status = this.$cookies.get('success-donation')
      if (this.isDonationSuccessful(donor, status)) {
        this.showDonationSuccessModal(donor, goal)
      }
    },

    isDonationSuccessful(donor, status) {
      return this.userMeta
           && this.$nuxt.context.from
           && this.$nuxt.context.from.name === 'manage-payment-detail-id'
           && donor
           && status
           && donor.userID === this.userMeta.id
    },

    showDonationSuccessModal(donor, goal) {
      this.successDonor = donor
      this.successGoal = goal
      this.successModal = true
      this.clearDonationCookies()
    },

    clearDonationCookies() {
      ['success-donor', 'success-goal', 'success-donation'].forEach(cookie => this.$cookies.remove(cookie))
    },

    async fetchLastDonorAndTotalSupporter() {
      try {
        const { data } = await this.$apiV2.get(`payment/supporters/summary?target_id=${this.userMeta?.id}`)
        this.lastDonorAndTotalSupporter = data
      } catch (error) {
        showError(error)
      }
    },
    // Old Code need cleanup
    resizeEvent: debounce(function _(event) {
      if (event.target.innerWidth < 600) {
        this.removeStickyElement()
        if (this.resizeObserver) {
          this.resizeObserver.disconnect()
        }
      } else {
        this.setStickyElement()
        this.setStickyProfileSidebar()
        this.observeProfileAboutHeight()
      }
    }, 1000),
    setStickyElement() {
      const tabbar = document.querySelector('.gank-tabs .ant-tabs-bar')
      if (tabbar && !this.stickyTabbar) {
        this.stickyTabbar = this.$stickySidebar(tabbar)
        const self = this
        tabbar.addEventListener('affix.top.stickySidebar', () => {
          const header = document.querySelector('header')
          if (header && self.$route.name === 'nickname') {
            header.classList.add('!relative')
          }
        })

        tabbar.addEventListener('affixed.static.stickySidebar', () => {
          const header = document.querySelector('header')
          if (header && self.$route.name === 'nickname') {
            header.classList.remove('!relative')
          }
        })
      }

      const sidebar = document.querySelector('#sidebar')
      if (sidebar && !this.stickySidebar) {
        this.stickySidebar = this.$stickySidebar(sidebar)

        // TODO: remove this after fix sticky sidebar bug (currenty can't set topSpacing)
        sidebar.addEventListener('affixed.top.stickySidebar', () => {
          const innerWrapperSticky = document.querySelector('#sidebar .inner-wrapper-sticky')
          // add top 75px for sticky sidebar for 4k screen
          if (window.innerWidth > 2000 && innerWrapperSticky) {
            innerWrapperSticky.style.top = '75px'
          }
        })

        sidebar.addEventListener('affixed.static.stickySidebar', () => {
          const innerWrapperSticky = document.querySelector('#sidebar .inner-wrapper-sticky')
          // add top 0px for sticky sidebar for 4k screen
          if (window.innerWidth > 2000 && innerWrapperSticky) {
            innerWrapperSticky.style.top = '0px'
          }
        })
      }

      const userSidebar = document.querySelector('#user')
      if (userSidebar && !this.stickyUserSidebar) {
        this.sidebarObserver = new IntersectionObserver(
          entries => {
            entries.forEach(x => {
              const buttonClassList = document.querySelector('#donate-follow-button')
              const profileBtn = document.querySelector('#profile-btn')
              const navScrollClassList = document.querySelector('.ant-tabs-nav-scroll')
              if (!x.isIntersecting) {
                if (navScrollClassList != null) {
                  navScrollClassList.classList.add('2xl:w-[1100px]')
                }
                if (
                  buttonClassList != null
                    && !window.location.href.includes('donate')
                    && !window.location.href.includes('feed')
                    && !window.location.href.includes('shop')
                ) {
                  buttonClassList.classList.add('fixed-support-button')
                }
                if (profileBtn != null) {
                  profileBtn.classList.add('fixed-support-right')
                }
              } else {
                if (navScrollClassList != null) {
                  navScrollClassList.classList.remove('2xl:w-[1100px]')
                }
                if (buttonClassList != null) {
                  buttonClassList.classList.remove('fixed-support-button')
                }
                if (profileBtn != null) {
                  profileBtn.classList.remove('fixed-support-right')
                }
              }
            })
          },
          {
            rootMargin: '0px',
            threshold: 0.25,
          },
        )

        this.sidebarObserver.observe(userSidebar)
      }
    },
    removeStickyElement() {
      if (this.stickyTabbar) {
        this.stickyTabbar = this.stickyTabbar.destroy()
      }
      if (this.stickySidebar) {
        this.stickySidebar = this.stickySidebar.destroy()
      }
      if (this.stickyProfileSidebar) {
        this.stickyProfileSidebar = this.stickyProfileSidebar.destroy()
      }
      if (this.sidebarObserver) {
        this.sidebarObserver = this.sidebarObserver.unobserve(document.querySelector('#user'))
      }
    },
    onReloadPost() {
      if (typeof this.$refs.userSocial !== 'undefined') this.$refs.userSocial.getPost()
      if (typeof this.$refs.userAbout !== 'undefined') this.$refs.userAbout.getPost()
    },
    isShowDark() {
      if (this.$route.name !== 'nickname') return true
      if (this.$route.name === 'nickname' && this.$colorMode.preference === 'dark') return true
      return false
    },
    async getMembership() {
      const endpoint = `/users/membership-settings-v2/${this.userMeta.id}`
      const url = this.isMyOwnProfile ? endpoint : `${endpoint}?isActive=true`

      try {
        if (!this.isMyOwnProfile && this.userMeta.has_membership && this.$auth.loggedIn) {
          await this.$store.dispatch('profile/getUserSubscriptions', this.userMeta.id)
        } else {
          this.$store.commit('profile/setFetchSubscriptionLoading', false)
        }
        const { data: { data } } = await this.$axios.get(url)
        this.membership = data
      } catch (error) {
        console.error('Failed to fetch membership settings:', error)
      }
    },
    handleTabChange(key) {
      const top = window.scrollY || document.documentElement.scrollTop
      if (top > 500) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
      this.$router.replace({
        ...this.$route,
        query: {
          tab: key,
        },
      }).catch(() => {})
      this.activeKey = key
    },
    donate() {
      this.$router.push({ name: 'nickname-donate', params: { ...this.$route.params } })
    },
    scrollToElement(param) {
      const list = {
        highlightEnableDonation: 'donation-card-section',
        highlightEnableMembership: 'membership-card-section',
        highlightCreatePost: 'post-card-section',
        highlightShopCard: 'shop-card-section',
      }

      const el = this.$el.querySelector(`.${list[param]}`)
      const headerOffset = 150

      if (!el) {
        // Redirect to the relevant page if element is not found
        const routeMap = {
          highlightCreatePost: '/post',
          highlightEnableMembership: '/manage/membership-settings',
          highlightShopCard: '/manage/shop',
        }

        if (param in routeMap) {
          this.$router.push(routeMap[param])
        }
        return
      }

      const elementPosition = el.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      if (window.innerWidth < 700 && param === 'highlightShopCard') {
        this.handleTabChange('shop')
      } else {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    },
    setStickyProfileSidebar() {
      const profileSidebar = document.querySelector('#profile-sidebar')
      if (profileSidebar && !this.stickyProfileSidebar) {
        this.stickyProfileSidebar = this.$stickySidebar(profileSidebar, {
          containerSelector: '#profile-about-section',
          innerWrapperSelector: '.profile-sidebar-inner',
        })

        this.stickyProfileSidebar.updateSticky()
      }
    },
    observeProfileAboutHeight() {
      const targetNode = this.$refs.profileAboutContainer.$el

      if (targetNode) {
        this.resizeObserver = new ResizeObserver(entries => {
          entries.forEach(entry => {
            this.profileAboutHeight = entry.contentRect.height
            if (this.stickyProfileSidebar) {
              this.stickyProfileSidebar.updateSticky()
            }
          })
        })
        this.resizeObserver.observe(targetNode)
      }
    },
  },

}
