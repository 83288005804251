
import get from 'lodash/get'
import InfiniteLoading from 'vue-infinite-loading'
import { showError } from '~/utils'
import { checkProfile } from '~/mixins/check-profile'
import SocialNewPost from '~/containers/UserProfile/Social/NewPost.vue'
import CategoriesBar from '~/components/Categories/NavBar.vue'
import { mapState, mapActions, mapGetters } from 'vuex'

const DSButton = () => import('~/components/DS/Button/Button.vue')
const IconPlus = () => import('~/components/DS/Icons/Ico/IcoPlus.vue')
const GankLoadingRive = () => import('~/components/Rive/Gank.vue')
const ScrollTop = () => import('~/components/Button/ScrollTop.vue')
const Post = () => import('~/containers/UserProfile/Social/Post.vue')
const PostFilter = () => import('~/containers/UserProfile/Social/PostFilter.vue')
const VoucherContainer = () => import('~/containers/Vouchers/components/VoucherContainer.vue')
const SupporterViewNotice = () => import('~/containers/UserProfile/SupporterViewNotice.vue')

const TipPaymentModal = () => import('~/components/Modal/DonationModal/TipPaymentModal.vue')

export default {
  name: 'UserSocial',
  components: {
    Post,
    DSButton,
    GankLoadingRive,
    InfiniteLoading,
    ScrollTop,
    SocialNewPost,
    CategoriesBar,
    PostFilter,
    VoucherContainer,
    SupporterViewNotice,
    TipPaymentModal,
    IconPlus,
  },
  mixins: [checkProfile],
  props: {
    user: {
      type: Object,
      required: true,
    },
    bus: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      posts: [],
      page: 1,
      show: false,
      videoPlayers: {},
      categories: [],
      activeCategory: undefined,
      infiniteId: +new Date(),
      isLoading: false,
      postAccessTypes: undefined,
      pinnedPosts: [],
      showTipPaymentModal: false,
      tipper: null,
    }
  },
  computed: {
    ...mapState({
      userDiscountPPV: state => state.profile.userDiscountPPV,
    }),
    ...mapGetters({
      isContextSupporter: 'menu/isContextSupporter',
    }),
    isMyOwnProfile() {
      const nickname = get(this.$auth, 'user.nickname')
      return nickname === this.user.nickname
    },
    isFollowing: {
      get() {
        return this.$store.state.profile.userFollowing.includes(this.user.id)
      },
      set(val) {
        this.$store.commit('profile/pushUserFollowing', val)
      },
    },
    isPinReachLimit() {
      return this.pinnedPosts && this.pinnedPosts.length >= 3
    },
    isLockedPostFilter() {
      return this.postAccessTypes === 'private,payPerView'
    },
    filterPinnedPosts() {
      const filteredPinnedPosts = this.pinnedPosts.filter(post => {
        const matchesCategory = !this.activeCategory || (post.postTags && post.postTags.some(tag => tag.id === this.activeCategory))
        const matchesAccess = !this.isLockedPostFilter || (post.accessType === 'private' || post.accessType === 'payPerView')

        return matchesCategory && matchesAccess
      })
      return filteredPinnedPosts
    },
  },
  watch: {
    $route(to, from) {
      if (from.query.tab === 'feed' && to.query.tab !== 'feed') {
        this.handleCategorySelected(undefined)
      }

      if (to.query.tab === 'feed' && to.query.category) {
        this.handleCategorySelected(to.query.category)
      }
    },
  },
  created() {
    this.fetchPinnedPosts()
  },
  beforeMount() {
    this.$nuxt.$on('video-played', (uid, plyr) => {
      this.videoPlayers[uid] = plyr

      const keys = Object.keys(this.videoPlayers)
      if (keys.length > 1) {
        keys.forEach(v => {
          if (v !== uid.toString()) {
            this.videoPlayers[v].player.pause()
          }
        })
      }
    })
    this.$nuxt.$on('video-stopped', uid => {
      delete this.videoPlayers[uid]
    })
  },
  async mounted() {
    const { category } = this.$route.query
    if (category) {
      this.activeCategory = category
    }
    await this.fetchCategories()
    this.show = true

    document.addEventListener('scroll', this.handleOnScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleOnScroll)
  },
  // eslint-disable-next-line no-unused-vars
  handleOnScroll(e) {
    Object.values(this.videoPlayers).forEach(v => {
      const rect = v?.$el.getBoundingClientRect()
      const y = rect.top + (window.pageYOffset || document.documentElement.scrollTop)
      const x = rect.left + (window.pageXOffset || document.documentElement.scrollLeft)
      const w = v.$el.offsetWidth
      const h = v.$el.offsetHeight
      const right = x + w
      const bottom = y + h
      const visibleX = Math.max(
        0,
        Math.min(w, window.pageXOffset + window.innerWidth - x, right - window.pageXOffset),
      )
      const visibleY = Math.max(
        0,
        Math.min(h, window.pageYOffset + window.innerHeight - y, bottom - window.pageYOffset),
      )
      const visible = (visibleX * visibleY) / (w * h)
      if (visible <= 0.8) {
        v.player.pause()
      }
    })
  },
  methods: {
    ...mapActions({
      loadAllUserDiscount: 'profile/loadAllUserDiscount',
    }),
    async infiniteHandler($state) {
      this.isLoading = true
      try {
        const params = new URLSearchParams({
          author: this.user.id,
          perPage: '20',
          page: this.page.toString(),
          excludePinned: true,
        })

        if (this.activeCategory) {
          params.append('tags', this.activeCategory)
        }

        if (this.postAccessTypes) {
          params.append('postAccessTypes', this.postAccessTypes)
        }

        const url = `/posts?${params.toString()}`

        const { data } = await this.$axios.$get(url)

        if (data && data.length > 0) {
          this.page += 1

          this.posts = this.posts.concat(data.filter(newData => this.posts.every(post => post.id !== newData.id)))
          $state.loaded()
        } else {
          $state.complete()
        }
      } catch (error) {
        $state.error()
      } finally {
        this.show = false
        this.isLoading = false
      }
    },
    async fetchCategories() {
      try {
        const params = new URLSearchParams({
          source: 'POST',
          is_active: true,
          user_id: this.user.id,
        })

        if (this.postAccessTypes === 'private,payPerView') {
          params.append('is_post_locked', true)
        }

        const { data } = await this.$apiV2.get(`/user-content/tags?${params.toString()}`)
        if (data.data) {
          this.categories = data.data
        }
      } catch (error) {
        showError(error)
      }
    },
    async getPost() {
      try {
        const res = await this.$axios.get(`/posts?author=${this.user.id}`)
        this.posts = get(res, 'data.data') || []
      } catch (error) {
        showError(error)
      }
    },
    async fetchPinnedPosts() {
      try {
        this.pinnedPosts = await this.$store.dispatch('post/getPinnedPostsByUserId', this.user.id)
      } catch (error) {
        showError(error)
      }
    },
    handleNewPost(v) {
      this.posts = [v, ...this.posts]
    },
    handleDeleted(v) {
      const idx = this.posts.findIndex(e => e.id === v)
      if (idx !== -1) {
        this.posts.splice(idx, 1)
      }
    },
    handleDeletePinnedPost(v) {
      this.pinnedPosts = this.pinnedPosts.filter(post => post.id !== v)
    },
    follow() {
      if (!this.$auth.user) {
        localStorage.setItem('trackSignup', true)
        this.$router.push('/login')
      } else {
        this.$axios
          .$post('/users/follow-profile', {
            followingID: this.user.id,
          })
          .then(() => {
            this.isFollowing = this.user.id
            this.$gtm.push({
              event: 'profile_follow',
            })
          })
      }
    },
    isShowDark() {
      if (this.$route.name !== 'nickname') return true
      if (this.$route.name === 'nickname' && this.$colorMode.preference === 'dark') return true
      return false
    },
    handleCategorySelected(category) {
      this.activeCategory = category
      this.page = 1
      this.posts = []
      this.infiniteId += 1
    },
    PostFilterChange(filter) {
      if (filter === 'Locked') {
        this.postAccessTypes = 'private,payPerView'
      } else {
        this.postAccessTypes = undefined
      }
      this.page = 1
      this.posts = []
      this.infiniteId += 1
      this.fetchCategories()
    },
    async handleUpdatePinnedPost(payload) {
      if (payload.isDeleted) {
        this.pinnedPosts = this.pinnedPosts.filter(v => v.id !== payload.post.id)
        this.updatePostData()
      } else {
        this.handleDeleted(payload.post.id)
        this.pinnedPosts = [payload.post, ...this.pinnedPosts]
        this.scrollToTop()
      }
    },
    scrollToTop() {
      if (this.refs && this.$refs?.topPost) {
        this.$refs.topPost.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    },
    updatePostData() {
      this.page = 1
      this.posts = []
      this.infiniteId += 1
    },
    handleTip(tipper) {
      this.tipper = tipper
      this.showTipPaymentModal = true
    },
    handleCloseTipPaymentModal() {
      this.showTipPaymentModal = false
      this.tipper = undefined
    },
  },
}
