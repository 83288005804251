import get from 'lodash/get'

export const checkProfile = {
  computed: {
    isMyOwnProfile() {
      const nickname = get(this.$auth, 'user.nickname', '')
      return nickname.toLowerCase() === this.$route.params.nickname
    },
  },
}
